import './App.css';
import React, { useState } from "react";

function App() {
  const [ restName, updateRestName ] = useState("Restaurant Name");
  const [ getBill, updateBill ] = useState(100);
  const [ getTip, updateTip ] = useState(15);
  const [ tipTotal, updateTipTotal ] = useState();
  const [ tipPerson, updateTipPerson ] = useState();

  function handleChange(e){
    let val = e.target.value;
    updateRestName(val);
  }

  function handleBillChange(e){
    updateBill(parseInt(e.target.value));
    console.log("After update: " + getBill);
  }

  function handleTip(e){
    updateTip(parseInt(e.target.value));
    console.log("After update: " + getTip);
  }

  function handleHomies(e){
    let thePeeps = parseInt(e.target.value);
    calcTipTotal(thePeeps);
  }

  function calcTipTotal(thePeeps){
    let val = (getTip * 0.01);
    let theTipTotal = getBill * val;
    updateTipTotal("$" + parseFloat(theTipTotal).toFixed(2));
    calc(theTipTotal, thePeeps);
  }

  function calc(theTipTotal, thePeeps){
    updateTipPerson("$" + parseFloat(theTipTotal / thePeeps).toFixed(2));
  }

  return (
    <div className="App">
        <h1>{restName}</h1>
        <label htmlFor="restaurant">Restaurant</label><input id="restaurant" type="input" onChange = {handleChange}></input>
        <label htmlFor="bill">Bill</label><input min="0" id="bill" type="number" onChange = {handleBillChange}></input>
        <label htmlFor="tip">Tip Percentage</label><input id="tip" min="0" type="number" onChange = {handleTip}></input>
        <label htmlFor="people">Number of People</label><input id="people" min="1" type="number" onChange = {handleHomies}></input>
        <p>Total Tip: {tipTotal}</p>
        <p>Tip Per Person: {tipPerson}</p>
    </div>
  );
}

export default App;
